<template>
    <div class="tinymce-editor">
        <editor v-model="myValue"
                :init="init"
                :disabled="disabled"
                @onClick="onClick">
        </editor>
    </div>
</template>
<script>
    import { upload } from '@/api/basic/common'

    import tinymce from 'tinymce/tinymce'
    import Editor from '@tinymce/tinymce-vue'
    import 'tinymce/themes/silver'
    // 编辑器插件plugins
    // 更多插件参考：https://www.tiny.cloud/docs/plugins/
    import 'tinymce/plugins/image'// 插入上传图片插件
    import 'tinymce/plugins/media'// 插入视频插件
    import 'tinymce/plugins/table'// 插入表格插件
    import 'tinymce/plugins/lists'// 列表插件
    import 'tinymce/plugins/wordcount'// 字数统计插件
    import 'tinymce/icons/default'
    export default {
        components: {
            Editor
        },
        props: {
            value: {
                type: String,
                default: ''
            },
            // 基本路径，默认为空根目录，如果你的项目发布后的地址为目录形式，
            // 即abc.com/tinymce，baseUrl需要配置成tinymce，不然发布后资源会找不到
            baseUrl: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            },
            plugins: {
                type: [String, Array],
                default: 'lists image media table wordcount'
            },
            toolbar: {
                type: [String, Array],
                default: 'undo redo |  formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists image media table | removeformat'
            }
        },
        data () {
            return {
                init: {
                    language_url: `${this.baseUrl}/tinymce/langs/zh_CN.js`,
                    language: 'zh_CN',
                    skin_url: `${this.baseUrl}/tinymce/skins/ui/oxide`,
                    content_css: `${this.baseUrl}/tinymce/skins/content/default/content.css`,
                    // skin_url: `${this.baseUrl}/tinymce/skins/ui/oxide-dark`, // 暗色系
                    // content_css: `${this.baseUrl}/tinymce/skins/content/dark/content.css`, // 暗色系
                    height: 300,
                    plugins: this.plugins,
                    toolbar: this.toolbar,
                    branding: false,
                    menubar: false,
                    powerpaste_word_import: 'propmt', // 参数可以是propmt, merge, clear
                    powerpaste_html_import: 'propmt', // propmt, merge, clear
                    powerpaste_allow_local_images: true, // 允许带图片
                    paste_data_images: true, // 设置为“true”将允许粘贴图像，而将其设置为“false”将不允许粘贴图像。
                    // 此处为图片上传处理函数，这个直接用了base64的图片形式上传图片，
                    // 如需ajax上传可参考https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler
                    // images_upload_handler: (blobInfo, success, failure) => {
                    //     const img = 'data:image/jpeg;base64,' + blobInfo.base64()
                    //     success(img)
                    // },
                    images_upload_handler: (blobInfo, success, failure, progress) => {
                        // tinymce api文档查阅可知   直接获取blob原始数据
                        var blob = blobInfo.blob();
                        // 原生上传
                        var formData = new FormData();
                        formData.append('file', blobInfo.blob(), blobInfo.filename());

                        upload(formData).then(response => {
                            // console.log(response)
                            if (response.code === 0){
                                var imgUrl = response.data.filePath
                                console.log(imgUrl)
                                // 上传代码返回结果之后，将图片插入到编辑器中
                                success(imgUrl);
                            } else {
                                this.$message.error('上传失败')
                            }
                        })

                    },





                    //    添加扩展插件
                    external_plugins: {
                        'powerpaste': `${this.baseUrl}/tinymce/powerpaste/plugin.min.js`
                    }
                },
                myValue: this.value
            }
        },
        mounted () {
            tinymce.init({})
        },
        methods: {
            // 添加相关的事件，可用的事件参照文档=> https://github.com/tinymce/tinymce-vue => All available events
            // 需要什么事件可以自己增加
            onClick (e) {
                this.$emit('onClick', e, tinymce)
            },
            // 可以添加一些自己的自定义事件，如清空内容
            clear () {
                this.myValue = ''
            }
        },
        watch: {
            value (newValue) {
                this.myValue = newValue
            },
            myValue (newValue) {
                this.$emit('input', newValue)
            }
        }
    }

</script>
